<template lang="pug">
Overlay(
  v-if='$sitewideConfig.config.internationalSitePopupEnabled && internationalSite'
  unique-key='internationalSite'
  animation-style='FADE'
  modal-width='XSMALL'
  mode='BLACK'
  @closed='close'
)
  template(#noPadding)
    div(class='py-6 px-4 text-white text-center')
      div(class='mb-6')
        span Welcome to&nbsp;
        span(class='font-bold') {{ $sitewideConfig.config.siteName }}
      div(class='mb-6') To shop for products that ship to your location, please visit our {{ internationalSite.siteText }}.
      div(class='flex gap-x-6 justify-center')
        button(class='underline py-3 px-2' @click='close') Stay on {{ internationalSite.originSite }} site
        NuxtLink(:to='internationalSite.domain' target='_blank' class='rounded bg-primary text-black py-3 px-2 font-bold') Go to {{ internationalSite.siteText }}
</template>

<script setup lang="ts">
const { $overlay, $geolocation, $sitewideConfig } = useNuxtApp()
const { getApiUrl } = useUrls()

const { data: internationalSites, error } = await useAsyncData('internationalSites', async () => {
  return await $fetch<InternationalSites[]>(getApiUrl('internationalSites'))
})

const countryCode = computed(() => {
  return $geolocation.edgeCountryCode
})

const internationalSite = computed(() => {
  if (!internationalSites.value || !countryCode.value) return
  return internationalSites.value.find((item) => item.countryCodes.includes(countryCode.value))
})

onMounted(() => {
  if (internationalSite.value) $overlay.open('internationalSite')
})

function close() {
  $overlay.close()
}

interface InternationalSites {
  domain: string
  siteText: string
  countryCodes: string[]
}
</script>
